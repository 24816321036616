import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { DesktopTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Drawer, InputLabel, SelectChangeEvent } from '@mui/material';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import GlobalStyles from '@mui/material/GlobalStyles';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import {
    ATTACHMENT_DATE_RANGE,
    ATTACHMENT_TYPE,
    getAttachmentType,
    getPeriodType,
    getReportType,
    PERIOD,
    RestaurantReportPreferences,
    REPORT_TYPE,
    IScheduledReports,
    WEEK_DAYS,
    BrandReportPreferences,
} from '@/services/utils/schedulerReport_helper';
import ScheduledReportsService from '@/services/scheduledReports';
import { useRestaurantContext } from '@/contexts/restaurant';
import { useTranslation } from '@/hooks/translations';
import { PrimaryButton } from '@/components/common';
import EmailAutocomplete from '@/components/EmailAutoComplete';
import { RestaurantOrderMode } from '@/views/OrdersTableView/types';

const customTimePickerStyles = (
    <GlobalStyles
        styles={{
            '.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClockSection-root:after': {
                height: 'auto',
            },
        }}
    />
);

export const initialRestaurantPreferences: RestaurantReportPreferences = {
    reportType: REPORT_TYPE.ORDER_VIEW,
    dateRange: ATTACHMENT_DATE_RANGE.TODAY,
    period: PERIOD.DAILY,
    attachmentType: ATTACHMENT_TYPE.NONE,
    reportTime: {
        hour: new Date().getHours().toString(),
        minute: new Date().getMinutes().toString(),
        day: WEEK_DAYS.SUNDAY,
    },
    cc: [],
    fileName: '',
};

const RestaurantScheduledReportEditor = ({ modalOpen, handleModalClose, editData, refresh }: IScheduledReports) => {
    const scheduledReportsService = ScheduledReportsService.getInstance();
    const { t } = useTranslation('common');
    const { restaurant } = useRestaurantContext();
    const { enqueueSnackbar } = useSnackbar();

    const [reportTime, setReportTime] = useState<Date | null>(new Date());
    const [loading, setLoading] = useState<boolean>(false);

    const [reportPreferences, setReportPreferences] =
        useState<RestaurantReportPreferences>(initialRestaurantPreferences);

    const isRestaurantQsr = restaurant?.order_mode === RestaurantOrderMode.QSR;

    useEffect(() => {
        if (!editData) return;

        const convertedDate = new Date();
        convertedDate.setHours(parseInt(editData?.payload?.report_time?.hour, 10));
        convertedDate.setMinutes(parseInt(editData?.payload?.report_time?.minute, 10));
        const xDateHour = parseInt(editData?.payload?.report_time?.hour, 10);
        const xDateMinute = parseInt(editData?.payload?.report_time?.minute, 10);

        setReportTime(
            new Date(
                convertedDate.getFullYear(),
                convertedDate.getMonth(),
                convertedDate.getDate(),
                xDateHour,
                xDateMinute,
            ),
        );

        setReportPreferences({
            ...reportPreferences,
            reportType: getReportType(editData.payload.report_type) || REPORT_TYPE.ORDER_VIEW,
            dateRange: editData.payload.date_range || ATTACHMENT_DATE_RANGE.TODAY,
            period: editData.payload.period || PERIOD.DAILY,
            attachmentType: editData.payload.attachment_type || ATTACHMENT_TYPE.NONE,
            reportTime: {
                hour: xDateHour.toString(),
                minute: xDateMinute.toString(),
                day: editData?.payload?.report_time?.day,
            },
            cc: editData.payload.cc,
        });
    }, [editData]);

    useEffect(() => {
        if (!modalOpen) {
            setReportPreferences(initialRestaurantPreferences);
        }
    }, [modalOpen]);

    const handleSave = () => {
        setLoading(true);
        scheduledReportsService
            .createRestaurantReport(
                {
                    ...reportPreferences,
                    dateRange: reportPreferences.dateRange,
                    timezoneId: restaurant?.timezone || '',
                },
                restaurant?.id || '',
            )
            .then(() => {
                refresh();
                enqueueSnackbar(t('Report created successfully'), { variant: 'success' });
            })
            .catch((err) => {
                enqueueSnackbar(`${err}`, { variant: 'error' });
            })
            .finally(() => {
                handleModalClose();
                setLoading(false);
            });
    };

    const handleUpdate = () => {
        setLoading(true);

        scheduledReportsService
            .updateRestaurantReport(
                {
                    ...reportPreferences,
                    timezoneId: restaurant?.timezone || '',
                },
                restaurant?.id || '',
                editData.id,
            )
            .then(() => {
                refresh();
                enqueueSnackbar(t('Report updated successfully'), { variant: 'success' });
            })
            .catch((err) => {
                enqueueSnackbar(`${err}`, { variant: 'error' });
            })
            .finally(() => {
                handleModalClose();
                setLoading(false);
            });
    };
    const handleTimeChange = (newValue: Date | null) => {
        if (newValue) {
            setReportTime(newValue);
            setReportPreferences({
                ...reportPreferences,
                reportTime: { hour: newValue.getHours().toString(), minute: newValue.getMinutes().toString() },
            });
        }
    };
    const handleReportTypeChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setReportPreferences({ ...reportPreferences, reportType: getReportType(e.target.value) });
    const handleDateRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);
        setReportPreferences({ ...reportPreferences, dateRange: e.target.value });
    };
    const handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReportPreferences((prev) => {
            return {
                ...prev,
                period: getPeriodType(e.target.value),
                reportTime: {
                    hour: reportPreferences.reportTime?.hour || new Date().getHours().toString(),
                    minute: reportPreferences.reportTime?.minute || new Date().getMinutes().toString(),
                    day: e.target.value === PERIOD.WEEKLY ? WEEK_DAYS.SUNDAY : undefined,
                },
            };
        });
    };
    const handleDayOfWeekChange = (event: SelectChangeEvent<WEEK_DAYS>) => {
        setReportPreferences({
            ...reportPreferences,
            reportTime: {
                hour: reportPreferences.reportTime?.hour || new Date().getHours().toString(),
                minute: reportPreferences.reportTime?.minute || new Date().getMinutes().toString(),
                day: (event.target.value as WEEK_DAYS) || WEEK_DAYS.SUNDAY,
            },
        });
    };

    return (
        <Drawer
            PaperProps={{
                sx: {
                    borderStartStartRadius: 32,
                    borderEndStartRadius: 32,
                    maxWidth: 500,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                },
            }}
            open={modalOpen}
            anchor="right"
            onClose={handleModalClose}
        >
            <Typography
                sx={{ fontSize: '30px', padding: '20px 24px', flexShrink: 0, borderBottom: '1px solid #EBECF2' }}
            >
                {editData ? t('Edit Report') : t('Create new report')}
            </Typography>
            <Grid
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px 24px',
                    overflowY: 'auto',
                    flexGrow: 1,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                        <FormControl>
                            <FormLabel
                                sx={{
                                    color: '#1A1C23',
                                    fontSize: '1rem',
                                }}
                            >
                                {t('Report Type')}
                            </FormLabel>
                            <RadioGroup
                                defaultValue={REPORT_TYPE.TRANSACTION_VIEW}
                                name="type-radio-group"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                                onChange={handleReportTypeChange}
                                value={reportPreferences.reportType}
                            >
                                <FormControlLabel
                                    value={REPORT_TYPE.ORDER_VIEW}
                                    control={<Radio />}
                                    label={t('Order View')}
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '.9rem',
                                        },
                                    }}
                                />
                                <FormControlLabel
                                    value={REPORT_TYPE.TRANSACTION_VIEW}
                                    control={<Radio />}
                                    label={t('Transaction View')}
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '.9rem',
                                        },
                                    }}
                                />
                                {false && (
                                    <FormControlLabel
                                        value={REPORT_TYPE.REVIEW_EXPORT}
                                        control={<Radio />}
                                        label={t('Review Metrics')}
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '.9rem',
                                            },
                                        }}
                                    />
                                )}
                                {isRestaurantQsr && (
                                    <FormControlLabel
                                        value={REPORT_TYPE.QSR_ORDER}
                                        control={<Radio />}
                                        label={t('QSR Order')}
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '.9rem',
                                            },
                                        }}
                                    />
                                )}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl
                            size="small"
                            sx={{
                                marginTop: '0.5rem',
                                width: '100%',
                            }}
                        >
                            <InputLabel>{t('Attachment Type')}</InputLabel>
                            <Select
                                label={t('Attachment Type')}
                                value={reportPreferences.attachmentType}
                                onChange={(event: SelectChangeEvent) => {
                                    setReportPreferences({
                                        ...reportPreferences,
                                        attachmentType: getAttachmentType(event.target.value),
                                    });
                                }}
                            >
                                <MenuItem value={ATTACHMENT_TYPE.NONE}>{t('none')}</MenuItem>
                                <MenuItem value={ATTACHMENT_TYPE.CSV}>{t('CSV')}</MenuItem>
                                <MenuItem value={ATTACHMENT_TYPE.XLSX}>{t('XLSX')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {reportPreferences.reportType !== REPORT_TYPE.REVIEW_EXPORT && (
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel
                                    sx={{
                                        color: '#1A1C23',
                                        fontSize: '1rem',
                                    }}
                                >
                                    {t('Date Range')}
                                </FormLabel>
                                <RadioGroup
                                    defaultValue={ATTACHMENT_DATE_RANGE.CURRENT_MONTH}
                                    name="period-radio-group"
                                    onChange={handleDateRangeChange}
                                    value={reportPreferences.dateRange}
                                >
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} sm={12}>
                                            <FormControlLabel
                                                value={ATTACHMENT_DATE_RANGE.YESTERDAY}
                                                control={<Radio />}
                                                label={t('Yesterday')}
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {
                                                        fontSize: '.9rem',
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12} sm={12} alignSelf="center">
                                            {dateStrings[0]}
                                        </Grid> */}
                                        <Grid item xs={12} sm={12}>
                                            <FormControlLabel
                                                value={ATTACHMENT_DATE_RANGE.TODAY}
                                                control={<Radio />}
                                                label={t('Today')}
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {
                                                        fontSize: '.9rem',
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12} sm={12} alignSelf="center">
                                            {dateStrings[1]}
                                        </Grid> */}
                                        <Grid item xs={12} sm={12}>
                                            <FormControlLabel
                                                value={ATTACHMENT_DATE_RANGE.CURRENT_MONTH}
                                                control={<Radio />}
                                                label={t('Current Month')}
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {
                                                        fontSize: '.9rem',
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12} sm={12} alignSelf="center">
                                            {dateStrings[2]}
                                        </Grid> */}
                                        <Grid item xs={12} sm={12}>
                                            <FormControlLabel
                                                value={ATTACHMENT_DATE_RANGE.CURRENT_WEEK}
                                                control={<Radio />}
                                                label={t('Current Week')}
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {
                                                        fontSize: '.9rem',
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12} sm={12} alignSelf="center">
                                            {dateStrings[3]}
                                        </Grid> */}
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel
                                sx={{
                                    color: '#1A1C23',
                                }}
                            >
                                {t('Other Recipients (cc)')}
                            </FormLabel>
                            <EmailAutocomplete
                                reportPreferences={reportPreferences}
                                setReportPreferences={(
                                    preferences: RestaurantReportPreferences | BrandReportPreferences,
                                ) => setReportPreferences(preferences as RestaurantReportPreferences)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel
                                sx={{
                                    color: '#1A1C23',
                                    fontSize: '1rem',
                                }}
                            >
                                {t('Frequency')}
                            </FormLabel>
                            <RadioGroup
                                defaultValue={PERIOD.DAILY}
                                name="period-radio-group"
                                onChange={handlePeriodChange}
                                value={reportPreferences.period}
                            >
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            value={PERIOD.DAILY}
                                            control={<Radio />}
                                            label={t('Daily')}
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: '.9rem',
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            value={PERIOD.WEEKLY}
                                            control={<Radio />}
                                            label={t('Weekly')}
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: '.9rem',
                                                },
                                            }}
                                        />
                                    </Grid>

                                    {reportPreferences.reportType !== REPORT_TYPE.REVIEW_EXPORT && (
                                        <>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    value={PERIOD.MONTHLY}
                                                    control={<Radio />}
                                                    label={t('Monthly')}
                                                    sx={{
                                                        '& .MuiFormControlLabel-label': {
                                                            fontSize: '.9rem',
                                                        },
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    value={PERIOD.HOURLY}
                                                    control={<Radio />}
                                                    label={t('Hourly')}
                                                    sx={{
                                                        '& .MuiFormControlLabel-label': {
                                                            fontSize: '.9rem',
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    <Grid container spacing={2}>
                                        {reportPreferences.period !== PERIOD.HOURLY && (
                                            <Grid item xs={reportPreferences.period === PERIOD.WEEKLY ? 6 : 12}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    {customTimePickerStyles}
                                                    <DesktopTimePicker
                                                        value={reportTime}
                                                        onChange={handleTimeChange}
                                                        slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        )}
                                        {reportPreferences.period === PERIOD.WEEKLY && (
                                            <Grid item xs={6}>
                                                <FormControl size="small" fullWidth>
                                                    <Select
                                                        value={reportPreferences.reportTime?.day}
                                                        onChange={handleDayOfWeekChange}
                                                        disabled={reportPreferences.period !== PERIOD.WEEKLY}
                                                    >
                                                        <MenuItem value={WEEK_DAYS.SUNDAY}>{t('Sunday')}</MenuItem>
                                                        <MenuItem value={WEEK_DAYS.MONDAY}>{t('Monday')}</MenuItem>
                                                        <MenuItem value={WEEK_DAYS.TUESDAY}>{t('Tuesday')}</MenuItem>
                                                        <MenuItem value={WEEK_DAYS.WEDNESDAY}>
                                                            {t('Wednesday')}
                                                        </MenuItem>
                                                        <MenuItem value={WEEK_DAYS.THURSDAY}>{t('Thursday')}</MenuItem>
                                                        <MenuItem value={WEEK_DAYS.FRIDAY}>{t('Friday')}</MenuItem>
                                                        <MenuItem value={WEEK_DAYS.SATURDAY}>{t('Saturday')}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between !important',
                    padding: ' 1rem 1.6rem 1.6rem 1.6rem',
                    backgroundColor: '#F8F8F8',
                    flexShrink: 0,
                }}
            >
                <PrimaryButton
                    id="cancel-btn"
                    styles={{ backgroundColor: '#C4C4C4' }}
                    color="grey"
                    onClick={handleModalClose}
                    text={t('Cancel')}
                />
                <PrimaryButton
                    id="action-btn"
                    onClick={editData ? handleUpdate : handleSave}
                    text={editData ? t('Update') : t('Create')}
                    disabled={loading}
                />
            </Grid>
        </Drawer>
    );
};

export default RestaurantScheduledReportEditor;
